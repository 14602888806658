import { FormLabel, Grid, Typography } from '@mui/material'
import React from 'react'
import { GatheringRequest } from '../../../../models/Gathering/Gathering'
import useStyles from './styles/GatheringConfirmationInfoModalStyle'

interface GatheringGeneralInfoProps {
  gatheringInfo: GatheringRequest
}

const GatheringGeneralInfo: React.FC<GatheringGeneralInfoProps> = ({ gatheringInfo }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} className={classes.card}>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="confirmation-modal-vehicle">
          <strong>Veículo / Motorista:</strong> {gatheringInfo.vehicleName || ''} / {gatheringInfo.driverName || ''}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" data-cy="confirmation-modal-observation">
          <strong>Observação:</strong> {gatheringInfo.mtrObservation || ''}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default GatheringGeneralInfo
