import ISystemUpdates from '../../../models/SystemUpdate/update'
import { CargoResponse } from '../../../models/User/user'
import companyPersistanceContent from './01-companyPersistance-update'
import clientInfoVisualizationContent from './02-clientInfoVisualization-update'
import partnerInfoVisualizationContent from './03-partnerInfoVisualization-update'
import allPartnersDocOptionContent from './04-allPartnersDocOption-update'
import bugFixAndOtimizationContent from './05-bugFixAndOtimization-update'
import financeTabInClientInfoView from './06-financeTabInClientInfoView-update'
import newResiduesFieldContent from './07-newResiduesField-update'
import newContractFileFieldUpdateContent from './08-contractFileField-update'
import cnpjCheckUpdateContent from './09-cnpjCheck-update'
import residuesQuantityFieldUpdateContent from './10-residues-quantity-field-update'
import feamWebServerStatusUpdateContent from './11-feam-system-status-update'

const reciclaEmployeesOnly = [
  'Administrador',
  'Tecnologia',
  'Sucesso do Cliente',
  'Experiência do Cliente',
  'Analista de Dados',
]

const systemUpdatesInfos: ISystemUpdates[] = [
  {
    identifier: '20241008at11',
    title: 'Indicação do status de funcionamento da FEAM',
    description: 'Agora será possível consultar o status de funcionamento da FEAM diretamente no sistema.',
    changesMade: ['Adição de indicador visual de status da FEAM nas páginas de listagem e formulário de MTR.'],
    date: '08 de outubro de 2024',
    content: feamWebServerStatusUpdateContent,
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    identifier: '20241003at10',
    title: 'Facilitação da edição de resíduos.',
    description: 'Facilitamos a edição da quantidade de cada resíduo na geração de MTR.',
    changesMade: [
      'Em vez de acessar cada resíduo individualmente para editar sua quantidade, você pode fazer isso diretamente na listagem.',
    ],
    date: '03 de outubro de 2024',
    content: residuesQuantityFieldUpdateContent,
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    identifier: '20241001at09',
    title: 'Nova checagem de CNPJ no cadastro e edição de clientes.',
    description:
      'Ao cadastrar ou atualizar um cliente, é feita uma verificação se o CNPJ inserido já está atrelado a outra empresa.',
    changesMade: [
      'Checagem instantânea de CNPJ no momento do cadastro ou edição de clientes, proporcionando mais transparência e eficiência no processo.',
    ],
    date: '01 de outubro de 2024',
    content: cnpjCheckUpdateContent,
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    identifier: '20240926at08',
    title: 'Novo campo para salvar o arquivo do contrato do Cliente',
    description: 'Atualização que adiciona um campo para salvar o arquivo PDF do contrato do Cliente.',
    changesMade: [
      'Adiciona o campo de arquivo PDF do contrato a aba "Dados do contrato" no formulário de cadastro e edição do Cliente.',
      'Adiciona o link para o arquivo do contrato a aba de "Dados do Contrato" na visualização de dados do Cliente.',
    ],
    date: '26 de setembro de 2024',
    content: newContractFileFieldUpdateContent,
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    identifier: '20240919at07',
    title: 'Novos campos sobre resíduos na geração de MTR',
    description: 'Agora você pode inserir mais informações internas do gerador sobre o resíduo.',
    changesMade: [
      'Adicionamos o campo "Código Interno no Gerador".',
      'Substituímos o antigo campo "Observações" por "Observações Internas do Gerador".',
    ],
    date: '19 de setembro de 2024',
    content: newResiduesFieldContent,
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    identifier: '20240910at06',
    title: 'Visualização financeira otimizada',
    description: 'Agora você pode consultar os dados financeiro do Cliente com poucos cliques.',
    changesMade: [
      'Adicionamos à caixa de visualização de dados do Cliente a aba "Financeiro", com tabelas de Custos, Receitas, Comissões e valores totais.',
    ],
    date: '10 de setembro de 2024',
    content: financeTabInClientInfoView,
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    identifier: '20240828at05',
    title: 'Otimização e reparação do sistema para a melhor experiência do usuário.',
    description: 'Nesta primeira otimização, trazemos melhorias de usabilidade e correções de bugs.',
    changesMade: [
      'Tornamos os campos de veículo e motorista, do formulário de Novo MTR, facultativos e de valores livres.',
      'Disponibilizamos a criação e visualização de observações (sobre clientes) para mais tipos de usuários.',
      'Corrigimos as instabilidades na criação de Modelos de MTR.',
      'Melhoramos a visualização do Monitor Verde e seu filtro de seleção de empresas.',
      'Melhoramos a visibilidade de campos obrigatórios não preenchidos no formulário de novo Cliente',
    ],
    date: '28 de agosto de 2024',
    content: bugFixAndOtimizationContent,
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    identifier: '20240820at04',
    title: 'Visualização ágil de documentação de parceiros',
    description: 'Com um clique você pode ver a documentação de todos os parceiros de uma só vez.',
    changesMade: [
      'Disponibiliza a opção "Todos os parceiros" na página "Documentação Ambiental". Tal opção disponibiliza uma listagem completa de todos os parceiros que atendem as empresas vinculadas a sua conta.',
    ],
    date: '20 de agosto de 2024',
    content: allPartnersDocOptionContent,
    allowedRoles: ['Acesso Básico'],
  },
  {
    identifier: '20240812at02',
    title: 'Visualização de dados dos Parceiros',
    description: 'É possível visualizar as informações do Parceiro com um clique.',
    changesMade: [
      'Disponibiliza a visualização detalhada de dados cadastrados na própria listagem de parceiros.',
      'Facilita o acesso a edição do parceiro selecionado.',
    ],
    date: '12 de agosto de 2024',
    content: partnerInfoVisualizationContent,
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    identifier: '20240809at02',
    title: 'Visualização de dados dos Clientes',
    description: 'Agora é possível visualizar as informações do Cliente com um clique.',
    changesMade: [
      'Adiciona uma visualização dinâmica e detalhada de cada empresa, na página de listagem de clientes.',
      'O acesso a edição do cliente selecionado foi facilitado através de um botão de edição na caixa de informações aberta.',
    ],
    date: '09 de agosto de 2024',
    content: clientInfoVisualizationContent,
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    identifier: '20240725at01',
    title: 'Agilidade na navegação',
    description: 'Selecione a unidade almejada e navegue entre as páginas sem perder a seleção.',
    changesMade: [
      'Persistência de uma empresa selecionada ao navegar entre as demais páginas, seja de listagem ou formulários.',
    ],
    date: '25 de julho de 2024',
    content: companyPersistanceContent,
    allowedRoles: ['Acesso Básico'],
  },
]

export const getUpdatesByUserPermissions = (userCargos: CargoResponse[]): ISystemUpdates[] => {
  const cargoNames = userCargos.map((cargo) => cargo.name)
  if (cargoNames.includes('Administrador')) return systemUpdatesInfos.slice(0, 5)

  return systemUpdatesInfos
    .filter((update) => cargoNames.some((cargo) => update.allowedRoles.includes(cargo)))
    .slice(0, 5)
}

export const getLatestUpdate = (userCargos: CargoResponse[]): ISystemUpdates => {
  return getUpdatesByUserPermissions(userCargos)[0]
}

export default systemUpdatesInfos
