import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { CompanyMinimalInfo } from '../../../../models/Company/Company'
import { GatheringRequest } from '../../../../models/Gathering/Gathering'
import GatheringCompanies from './GatheringCompanies'
import GatheringGeneralInfo from './GatheringGeneralInfo'
import GatheringResidues from './GatheringResidues'
import useStyles from './styles/GatheringConfirmationInfoModalStyle'

interface InfoConfirmationDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  onSubmit: () => void
  gatheringInfo: GatheringRequest
}

const InfoConfirmationDialog: React.FC<InfoConfirmationDialogProps> = ({ open, setOpen, onSubmit, gatheringInfo }) => {
  const classes = useStyles()
  const handleCloseModal = (): void => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      className={classes.dialog}
    >
      <DialogTitle sx={{ backgroundColor: '#43b849', color: 'white' }}>Confirmação de Dados do MTR</DialogTitle>
      <DialogContent dividers>
        <GatheringCompanies gatheringInfo={gatheringInfo} />
        <GatheringGeneralInfo gatheringInfo={gatheringInfo} />
        <GatheringResidues wasteList={gatheringInfo.waste} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          Cancelar
        </Button>
        <Button onClick={onSubmit} color="primary" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InfoConfirmationDialog
