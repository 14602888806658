import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    companyGrid: {
      '&.MuiGrid-root': {
        marginLeft: '2px',
        marginBottom: '10px',
      },
    },
    card: {
      '&.MuiGrid-root': {
        marginTop: '10px',
        marginLeft: '2px',
        marginBottom: '10px',
        overflow: 'hidden',
        width: '95%',
      },
    },
    dialog: {
      '&.MuiDialog-root': {
        zIndex: 1000,
        overflow: 'hidden',
      },
    },
    tabTitle: {
      fontWeight: 'lighter',
    },
    labelStyle: {
      '&.MuiFormLabel-root': {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '16px',
        letterSpacing: '0em',
        color: '#484946',
      },
    },
    gridsFlexColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'spaceBetween',
      height: '100%',
    },
  })
)

export default useStyles
